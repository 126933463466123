'use client'

import { createContext, useContext, ReactNode } from 'react'
import { FullAuthUser } from '@premeeting/lib/types'

interface UserContextProps {
  authUser: FullAuthUser | null
}

const UserContext = createContext<UserContextProps | undefined>(undefined)

export function UserProvider({
  authUser,
  children,
}: {
  authUser: FullAuthUser | null
  children: ReactNode
}) {
  return (
    <UserContext.Provider value={{ authUser }}>{children}</UserContext.Provider>
  )
}

export function useAuthUser() {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
