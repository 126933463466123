'use client'
import { FC, PropsWithChildren } from 'react'
import styles from './RouteStyleProvider.module.scss'
import classNamesBind from 'classnames/bind'
import { usePathname } from 'next/navigation'
const cn = classNamesBind.bind(styles)

const RouteStyleProvider: FC<PropsWithChildren> = ({ children }) => {
  const pathName = usePathname()

  return (
    <body
      className={cn('body', {
        enterprise: ['/people'].includes(pathName || ''),
      })}
    >
      {children}
    </body>
  )
}

export default RouteStyleProvider
